.ticket-generator {
  text-align: center;
  margin-top: 20px;
}

.ticket-container {
  position: relative;
  width: 90%; /* Adjust width for responsiveness */
  max-width: 1000px; /* Ensure it doesn't exceed original width */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto;
}

.ticket-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ticket-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: "Arial", sans-serif;
  font-weight: bold;
  color: white;
}

/* Ticket Number */
.ticket-number {
  position: absolute;
  top: 11%; /* Relative positioning for flexibility */
  right: 91%; /* Adjust alignment */
  font-size: 2vw; /* Use viewport width for scaling */
  font-weight: 800;
  transform: rotate(-90deg);
}

/* Row Number */
.row-number {
  position: absolute;
  top: 37%; /* Relative positioning for flexibility */
  right: 13%; /* Adjust alignment */
  font-size: 3vw; /* Use viewport width for scaling */
  font-weight: 800;
  transform: rotate(-90deg);
}

/* Seat Number */
.seat-number {
  position: absolute;
  bottom: 70%; /* Relative positioning for flexibility */
  right: 13%; /* Adjust alignment */
  font-size: 3vw; /* Use viewport width for scaling */
  font-weight: 800;
  transform: rotate(-90deg);
}

/* Buttons */
.generate-button,
.download-button {
  margin-top: 20px;
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.generate-button {
  background-color: #1de9b6;
  color: white;
}

.generate-button:hover {
  background-color: #17c8a1;
  transform: scale(1.05);
}

.download-button {
  background-color: #3b82f6;
  color: white;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.download-button:hover {
  background-color: #2563eb;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ticket-number,
  .row-number,
  .seat-number {
    font-size: 4vw; /* Reduce font size for medium screens */
  }

  .generate-button,
  .download-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .ticket-container {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
  }

  .ticket-number{
  top: 11%; /* Relative positioning for flexibility */
  right: 90.5%; /* Adjust alignment */
  font-size: 3vw; /* Use viewport width for scaling */
  }
  .row-number{
  top: 30%; /* Relative positioning for flexibility */
  right: 12.5%; /* Adjust alignment */
  font-size: 4vw; /* Use viewport width for scaling */
  }
  .seat-number {
  bottom: 67%; /* Relative positioning for flexibility */
  right: 12.5%; /* Adjust alignment */
  font-size: 4vw; /* Use viewport width for scaling */
  }

  .generate-button,
  .download-button {
    font-size: 12px;
    padding: 8px 16px; /* Smaller button sizes */
  }
}
