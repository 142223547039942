/* General Page Styles */
body {
  margin: 0;
  font-family: "Arial", sans-serif;
  background-color: #0d1117; /* Dark background */
  color: #c9d1d9; /* Light text */
  line-height: 1.6;
  text-align: center;
}

h1, h2 {
  margin: 0;
}

h1 {
  font-size: 3rem;
  color: #58a6ff; /* Blue accent */
}

h2 {
  font-size: 2.5rem;
  color: #1f6feb; /* Slightly darker blue */
}

p {
  font-size: 1rem;
  margin: 10px 0;
}

.subtitle {
  font-size: 1.2rem;
  color: #8b949e; /* Subtle gray */
  margin-top: 10px;
}

.event-details {
  font-size: 1rem;
  margin: 15px 0;
  color: #e6edf3;
}

/* Logo */
.logo {
  width: 150px;
  margin-bottom: 20px;
}

/* Hero Section */
.hero {
  padding: 40px 20px;
  background-color: #0d1117; /* Dark theme */
  border-bottom: 1px solid #30363d; /* Subtle border */
}

/* Ticket Section */
.ticket-section {

  background-color: #161b22; /* Slightly lighter dark */
}

.ticket-section h2 {
  font-size: 2rem;

  color: #58a6ff; /* Blue accent */
}

/* Share Buttons */
.share-buttons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.share-button {
  padding: 10px 15px;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
}

.linkedin {
  background-color: #0077b5;
}

.twitter {
  background-color: #1da1f2;
}

.facebook {
  background-color: #1877f2;
}

.share-button:hover {
  opacity: 0.8;
}





.download-button {
  margin-top: 20px;
  padding: 12px 20px;
  background-color: #3b82f6;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.download-button:hover {
  background-color: #2563eb;
}

/* Responsive Design */
@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1rem;
  }

  .logo {
    width: 150px;
  }

  .ticket-container {
    width: 95%;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 0.9rem;
  }

  .ticket-container {
    width: 100%;
  }

  .logo {
    width: 150px;
  }
}
